import { Stack } from "@mantine/core";
import mama from "../assets/mama.jpg";
import { Heading } from "../components/Heading";
import { Page } from "./Page";

export function Vita() {
  return (
    <Page>
      <Stack className="px-10 py-8">
        <div className="flex flex-col md:flex-row">
          <div className="md:w-2/3">
            {" "}
            <Heading>Vita</Heading>
            <p>
              Almut Baumgarten, geboren 1969, wuchs auf der Schwäbischen Alb und
              im Südschwarzwald auf. Sie studierte Philosophie,
              Literaturwissenschaft und Soziologie in Freiburg. Heute lebt sie
              im Bergischen Land. Sie schreibt Prosa und Theatertexte und lehrt
              an der Folkwang Universität der Künste in Essen.{" "}
            </p>
            <br />
            <Heading>Auszeichnungen (Auswahl)</Heading>
            <p>
              Else Lasker-Schüler-Stücke-Preis <br />
              Jugendtheaterpreis Baden-Württemberg <br />
              Dramatikerinnenpreis NRW „Reif für die Bühne“ <br />
            </p>
            <br />
            <Heading>Uraufführungen</Heading>
            <p>
              TANK 2008 Pfalztheater Kaiserslautern, Regie: Thilo Voggentreiter
              <br />
              HILDE 2009 Pfalztheater Kaiserslautern, Regie: Iris Beyer
              <br />
              FLIEGEN 2010 Theater Bautzen in sorbischer Übersetzung,
              anschließend auch in deutscher Sprache, Regie: Torsten Schlosser
              <br />
              SILVERDAY 2010 SETA Düsseldorf, Regie: Marlin de Haan <br />
              IM BLÜTENSTAUB 2023 MuKu Bielefeld, Regie: Annelena Balke
            </p>
            <br />
            <Heading>Veröffentlichungen</Heading>
            <p>
              Mucksmenschenstill, mixtvision Verlag 2012 <br />
              Eine Jacke, die sich nach dem Winter sehnt, Dezember 2022
              <br />
              Seit 2004 zahlreiche Erzählungen in Literaturzeitschriften, u. a.
              in Konzepte, entwürfe, Macondo
              <br />
            </p>
          </div>
          <div className="md:pl-5 md:w-1/3">
            <img
              className="object-cover w-full grayscale hover:grayscale-0"
              alt={"bild"}
              src={mama}
            />
            <p className="text-right">Foto: Victor Gurov</p>
          </div>
        </div>
      </Stack>
    </Page>
  );
}
