import { Heading } from "../Heading";

export function Mucksmenschen() {
  return (
    <>
      <Heading>Mucksmenschenstill</Heading>
      <p>
        <br />
        Erzählendes Kinderbuch in der Reihe Dramatiker erzählen für Kinder mit
        Illustrationen von Alexandra Junge mixtvision Verlag 2012 <br />
        <br />K wacht eines Morgens auf und ist allein. Die anderen Menschen
        sind nicht verschwunden. Sie schlafen. Keiner außer ihr wird wach. Erst
        wird ihr angst und bang. Dann findet sie Gefallen dran: Die Supermärkte
        sind gefüllt mit feinen Sachen. Sie kann mit ihrem Fahrrad mitten auf
        der Straße fahren. Doch schon bald packt K die Langeweile und die
        Einsamkeit. Und dann sind da noch ganz praktische Fragen: Wohin mit dem
        Müll? Was, wenn die letzte Milch im Supermarkt sauer wird? <br /> <br />
        Mucksmenschenstill gibt es zum Beispiel{" "}
        <a
          target="_blank"
          href="https://www.orbanundstreu.de/katalog/detail/30980AB/"
          rel="noreferrer"
          className="underline"
        >
          hier
        </a>
        .
        <br /> <br />
        <b className="text-2xl">Stimmen</b> <br />
        »Mucksmenschenstill« ist eines dieser kleinen, feinen Bücher, die zwar
        ganz ruhig erzählt sind, aber lange nachhallen. schmitz junior. die
        buchhandlung für kinder <br /> <br />
        Offene, poetische Studie über einen sehr einsamen Geburtstag Jürgen
        Reuß, Badische Zeitung <br /> <br />
        In Mucksmenschenstill ist es anders herum als bei Dornröschen. Alle
        schlafen, nur ein Mädchen ist wach. Dieses dramatische Buch fesselt mit
        außergewöhnlichen Situationen. Kiek mal, Die Berliner Kinder- und
        Familienzeitung
        <br /> <br />
        Ein schwieriges Thema ist hervorragend aufbereitet …, ohne dass die
        Sprache irgendwie "vereinfacht" erscheint. Arbeitsgemeinschaft
        Jugendliteratur und Medien der GEW
        <br />
        <br />
        <b className="text-2xl">Textauszug</b>
        <br />
        Alles schlief, als K erwachte: das Haus, die Stadt, das ganze Land.
        Niemand wurde wach außer ihr. <br />
        <br />
        K merkte gleich, dass etwas anders war als sonst. Es war so seltsam
        still, als sie die Augen aufschlug. In der Kastanie vor dem Fenster
        sangen ein paar Vögel, am Fenster brummte eine Fliege, die den Weg nach
        draußen nicht mehr fand, und in der Ferne bellte traurig ein Hund. Das
        war alles, was K hörte. Kein Klappern in der Küche und kein Radio. Kein
        Gezanke der Zwillinge, wer das Klo zuerst benutzen durfte. Kein „Wo ist
        denn meine hellblaue Krawatte?“ von ihrem Vater, kein „Weiß ich doch
        nicht“ von ihrer Mutter. Auch kein Glockengeläut und kein
        Straßenverkehr. <br />
        <br />
        Kein einziges Menschengeräusch. Nichts war zu hören. Nur die Vögel und
        die Fliege und der Hund. Es war mucksmenschenstill.
      </p>
    </>
  );
}
