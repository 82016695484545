import { Group, Stack } from "@mantine/core";

export function Footer() {
  return (
    <Stack className="w-full p-4 text-xs text-center text-white bg-gray-600 border">
      <p>Alle Rechte vorbehalten. © Almut Baumgarten</p>
      <Group position="center">
        <a className="underline" href="kontakt">
          Impressum
        </a>
      </Group>
      <p>
        Alle auf dieser Internetpräsenz verwendeten Texte, Fotos und
        Illustrationen sind durch uns oder andere Berechtigte urheberrechtlich
        geschützt. Jede Art der Vervielfältigung ohne Genehmigung ist
        unzulässig. Sollten Sie Teile hiervon verwenden wollen, wenden Sie sich
        bitte an uns. Wir werden den Kontakt zu den Nutzungsberechtigten für Sie
        herstellen.
      </p>
    </Stack>
  );
}
