import { Stack } from "@mantine/core";
import { Heading } from "../components/Heading";
import { Page } from "./Page";

export function Kontakt() {
  return (
    <Page>
      <Stack className="px-10 py-8">
        <Heading>Kontakt</Heading>

        <p>
          Telefon: 02052 928189
          <br />
          E-Mail:{" "}
          <a className="underline" href="mailto:almut.baumgarten@t-online.de">
            almut.baumgarten@t-online.de
          </a>
          <br />
        </p>
        <Heading>Impressum</Heading>
        <p>
          Angaben gem. § 5 TMG:
          <br />
          <br />
          Almut Baumgarten
          <br />
          Hauptstraße 63
          <br />
          42555 Velbert
          <br />
          <br />
          <b className="text-xl">Haftung für Inhalte</b> <br />
          Alle Inhalte wurden mit größter Sorgfalt und nach bestem Gewissen
          erstellt. Für die Richtigkeit, Vollständigkeit und Aktualität der
          Inhalte kann ich jedoch keine Gewähr übernehmen. Als Diensteanbieterin
          bin ich gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
          den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG bin ich
          als Diensteanbieterin jedoch nicht verpflichtet, übermittelte oder
          gespeicherte fremde Informationen zu überwachen oder nach Umständen zu
          forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
          Verpflichtungen zur Entfernung oder Sperrung der Nutzung von
          Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt.
          Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der
          Kenntniserlangung einer konkreten Rechtsverletzung möglich. Bei
          Bekanntwerden von den o.g. Rechtsverletzungen werde ich diese Inhalte
          unverzüglich entfernen.
          <br /> <br />
          <b className="text-xl">Haftungsbeschränkung für externe Links</b>
          <br />
          Diese Webseite enthält Links auf externe Webseiten Dritter. Auf die
          Inhalte dieser direkt oder indirekt verlinkten Webseiten habe ich
          keinen Einfluss. Daher kann ich für die „externen Links“ auch keine
          Gewähr auf Richtigkeit der Inhalte übernehmen. Für die Inhalte der
          externen Links sind die jeweiligen Anbieter*innen oder Betreiber*innen
          (Urheber) der Seiten verantwortlich. Die externen Links wurden zum
          Zeitpunkt der Linksetzung auf eventuelle Rechtsverstöße überprüft und
          waren im Zeitpunkt der Linksetzung frei von rechtswidrigen Inhalten.
          Eine ständige inhaltliche Überprüfung der externen Links ist ohne
          konkrete Anhaltspunkte einer Rechtsverletzung nicht möglich. Bei
          direkten oder indirekten Verlinkungen auf die Webseiten Dritter, die
          außerhalb meines Verantwortungsbereichs liegen, würde eine
          Haftungsverpflichtung ausschließlich in dem Fall nur bestehen, wenn
          ich von den Inhalten Kenntnis erlangen und es mir technisch möglich
          und zumutbar wäre, die Nutzung im Falle rechtswidriger Inhalte zu
          verhindern. Für illegale, fehlerhafte oder unvollständige Inhalte und
          insbesondere für Schäden, die aus der Nutzung oder Nichtnutzung
          solcherart dargestellten Informationen entstehen, haftet allein
          der*die Diensteanbieter*in der Seite, auf die verwiesen wurde, nicht
          wer über Links auf die jeweilige Veröffentlichung lediglich verweist.
          Werden mir Rechtsverletzungen bekannt, werde ich die externen Links
          unverzüglich entfernen.
          <br /> <br />
          <b className="text-xl">Urheberrecht</b>
          <br />
          Die auf unserer Webseite veröffentlichen Inhalte und Werke unterliegen
          dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
          Verbreitung und jede Art der Verwertung des geistigen Eigentums in
          ideeller und materieller Sicht des Urhebers außerhalb der Grenzen des
          Urheberrechtes bedürfen der vorherigen schriftlichen Zustimmung von
          Almut Baumgarten. Downloads und Kopien dieser Seite sind nur für den
          privaten und nicht kommerziellen Gebrauch erlaubt. <br />
          <br />
          Dieses Impressum wurde freundlicherweise von jurarat.de zur Verfügung
          gestellt.
        </p>
      </Stack>
    </Page>
  );
}
