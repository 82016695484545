import { Carousel } from "@mantine/carousel";
import img1 from "../assets/home/1.jpg";
import img2 from "../assets/home/2.jpg";
import img3 from "../assets/home/3.jpg";
import img5 from "../assets/home/5.jpg";

export function Slides() {
  return (
    <>
      <Slide img={img1} right>
        dann rauschst du fort
        <br /> und schaust zurück,
        <br /> meinst fast, du könntest dich noch stehen sehn.
        <br /> und wie du immer kleiner wirst
        <br />
        in diesem krach.
        <br /> bist drauf und dran zu winken.
        <br /> wie zum abschied.
      </Slide>
      <Slide img={img3}>
        <span className="text-[#899FA5]">
          Eine Traurigkeit, die sie nicht sah, die zählte nicht. <br />
          Sie rechnete die Traurigkeit nicht mit.
          <br /> Es lag ja ohnehin die falsche Seite vorne.
        </span>
      </Slide>

      <Slide img={img5} top>
        <span className="text-[#631300]">
          Der Junge tut ja nichts.
          <br /> Es ist mir unerklärlich,
          <br /> woher er seine Trägheit hat, <br />
          diese unbedingte Tatenlosigkeit.
        </span>
      </Slide>
      <Slide img={img2}>
        nach der blüte <br />
        sehn sie aus wie greisenköpfe.
        <br />
        die früchte tragen kleine kronen
        <br />
        aus weichem, zuckerweißem haar.
        <br />
        daher hat die gattung ihren namen.
        <br />
        greiskraut.
      </Slide>
    </>
  );
}

function Slide({ children, img, right = false, top = false }) {
  return (
    <Carousel.Slide>
      <div className="flex flex-col w-full h-full ">
        <div
          style={{
            backgroundImage: `url(${img})`,
            backgroundSize: "cover",
          }}
          className={`w-full flex ${top ? "items-start" : "items-end"} ${
            right && "justify-end text-end"
          } p-10   h-full object-cover grayscale hover:grayscale-0`}
        >
          <p className={`${top ? "md:mb-40" : "md:mt-40"}`}>{children}</p>
        </div>
      </div>
    </Carousel.Slide>
  );
}
