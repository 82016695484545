import { Button, Grid, Group, Stack } from "@mantine/core";
import { useEffect, useRef, useState } from "react";
import { CSSTransition } from "react-transition-group";
import back from "../assets/memory/Memorie_korr23.03.jpg";

import "./memorycss.css";

function shuffleArray(array) {
  let shuffled = array
    .map((value) => ({ value, sort: Math.random() }))
    .sort((a, b) => a.sort - b.sort)
    .map(({ value }) => value);
  return shuffled;
}

const numberOfImages = 3;

export function Memory({ images }) {
  const getBaseSetup = () =>
    shuffleArray(
      [...Array(numberOfImages * 2).keys()].map((i) => ({
        bild: Math.floor(i / 2),
        img: images[i],
        umgrdreht: false,
      }))
    );

  const [memory, setMemory] = useState(getBaseSetup());

  const [lookingAt, setLookingAt] = useState([]);

  const [doneModal, setDoneModal] = useState(false);

  const timer = useRef(null);

  const restartGame = () => {
    setMemory(
      memory.map((karte, index) => {
        return {
          ...karte,
          umgrdreht: false,
        };
      })
    );
    setLookingAt([]);
    setTimeout(() => {
      setMemory(getBaseSetup());
    }, 200);
  };

  useEffect(() => {
    if (lookingAt.length === 2) {
      if (memory[lookingAt[0]].bild === memory[lookingAt[1]].bild) {
        setMemory(
          memory.map((karte, index) => {
            if (index === lookingAt[0] || index === lookingAt[1]) {
              return {
                ...karte,
                umgrdreht: true,
              };
            }
            return karte;
          })
        );

        setLookingAt([]);
      } else {
        timer.current = setTimeout(() => {
          setLookingAt([]);
        }, 2000);
      }
    }
  }, [lookingAt, memory]);

  return (
    <Stack className="items-center p-10">
      <Grid className="max-w-[720px] max-h-[720px]">
        {memory.map((karte, index) => (
          <Grid.Col span={4} key={`karte-${index}`}>
            <CSSTransition
              in={memory.every((karte) => karte.umgrdreht)}
              timeout={200 + index * 100}
              classNames="done"
              onEntered={() => {
                if (karte.bild === numberOfImages - 1)
                  setTimeout(() => setDoneModal(true), 1200);
              }}
            >
              <CSSTransition
                in={lookingAt.includes(index) || karte.umgrdreht}
                timeout={200}
                classNames="flip"
              >
                <div
                  onClick={() => {
                    if (lookingAt.length === 2 || karte.umgrdreht) return;
                    setLookingAt([...lookingAt, index]);
                  }}
                  className="relative flex items-center justify-center border card"
                >
                  <img className="card-back" alt="alt" src={karte.img} />
                  <img alt="card-front" src={back} />
                </div>
              </CSSTransition>
            </CSSTransition>
          </Grid.Col>
        ))}
      </Grid>
      {doneModal && (
        <Group>
          <Button
            className="bg-[#325d65] text-[#dea739] hover:bg-[#325d65]/90"
            onClick={() => {
              restartGame();
              setDoneModal(false);
            }}
          >
            Noch einmal! – Ще раз!
          </Button>
        </Group>
      )}
      {/*  <Button
        className="bg-red-600"
        onClick={() =>
          setMemory(
            memory.map((karte, index) => {
              return {
                ...karte,
                umgrdreht: true,
              };
            })
          )
        }
      >
        Cheat
      </Button>*/}
    </Stack>
  );
}
