import { Heading } from "../Heading";

export function Silverday() {
  return (
    <>
      <Heading>SILVERDAY | UA 2010 SETA Düsseldorf </Heading>
      <p className="mb-8 md:mb-0 md:mr-8">
        <br />
        Donnerstags ist „Silverday“. Donnerstags trainieren keine jugendlich
        schlanken Kraftmenschen, donnerstags kämpfen sich hier die Alten ab:
        Beinpressen und Rudern, Bank- und Nackendrücken, Dips und Hantelcurls.
        Donnerstags heißt es im „bodystyle“ für drei, vier Stunden Kraftsport
        70plus. An diesem Donnerstag stürmt ein Affenmensch ins Studio und
        bringt die acht Sportler*innen samt ihrem Trainer in seine Gewalt – und
        ans Reden. Zeit genug haben sie: Die Leute draußen scheinen es mit der
        Befreiung der Geiseln nicht sonderlich eilig zu haben.
      </p>
      <p>
        <br /> <b className="text-2xl">Stimmen</b> <br />
        <i>
          Eine temporeiche Gesellschafts-Satire (…), die mit witzigen Dialogen,
          Situationskomik und überraschenden Wendungen unkonventionelle
          Spielangebote für ein 10-köpfiges Seniorentheater-Ensemble macht.{" "}
        </i>
        Aus der Begründung der Jury
      </p>{" "}
      <br />
      <p className="">
        <b className="text-2xl">Textauszug</b>
        <br />
        IDA: Die Idee kam ja von dir. Die mit dem Silverday. Ohne dich wär'n wir
        nicht hier. Ich wusste gar nicht, dass es so was gibt, wie einen
        Silverday. So ein spezielles Krafttraining für unsereins, so einen
        Altennachmittag im Fitnessstudio, so einen Kraftvergeudungs- und
        Verschwendungstag, einen Tag, speziell für uns gemacht. So einen
        Kraftausbeutungsirrsinn. Ohne dich würd ich jetzt Spätzle schaben für
        die Jungs. -<br />
        Aber Elly muss ja auch mit 75 noch einen straffen Hintern haben. Und
        braucht dafür eine Begleitung. Dass ihr die Zeit nicht lang wird auf dem
        Band, zerrt sie auch uns hierher. Damit wir neben ihr. Zu ihrer
        Unterhaltung. Wir keuchen uns hier unsre Lungen aus dem alten Leib, nur,
        damit die süße Elly dem Kuno einen hübschen Arsch vorweisen kann. Wenn
        wir gleich an der Bar ein Eiskaffee, ein Erdbeerschnittchen oder was...
        Das nimmt sie alles gar nicht, unsre Elly, die Elly trinkt ja nur einen
        Kaffee mit einem Süßli, einem halben Süßli! Für deinen sagenhaften
        Arsch! Damit der Kuno ihm nicht ansieht, wie alt der Ellyarsch
        tatsächlich ist. - Dabei will er gar nichts von ihr wissen. Er sieht sie
        gar nicht an.
        <br /> ELLY: -<br />
        DAGMAR: Vielleicht ist es ja andersrum. Vielleicht haben sie den
        Silverday gar nicht für uns gemacht. Damit wir hier für uns, und ohne
        dass sich einer lustig macht, an die Geräte können. Vielleicht wurde der
        Silverday erdacht, weil die jungen Leute lieber unter sich und ohne uns
        trainieren wollen. Damit wir ihnen nicht im Weg sind und das Bild nicht
        stören. Das Körperbild. Damit sie glauben können, was sie glauben
        wollen: Wenn wir nur hart genug trainieren, dann halten wir ihn auf,
        unsern Verfall. So ist es doch, nicht wahr, Uli, das ist der Grund für
        diesen Silverday. Dass ihr den Anblick nicht ertragen könnt. Unsern
        Anblick.
      </p>
    </>
  );
}
