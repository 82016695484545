import { Heading } from "../Heading";

export function Hilde() {
  return (
    <>
      <Heading>HILDE | UA 2009 am Pfalztheater Kaiserslautern</Heading>

      <p className="mb-8 md:mb-0 md:mr-8">
        <br /> Sie ist jung, sie ist schön und sie singt wie keine sonst: Hilde
        Mattauch ist Opernsängein an der Pfalzoper in Kaiserslautern – bis 1933:
        Das Terror-Regime der Nazis zwingt Hilde zur Flucht zunächst nach
        England, später mit ihrem kleinen Sohn Peter nach Portugal und
        schließlich nach Argentinien. Viele Jahre später feiert die Sopranistin
        an den größten Opernhäusern Amerikas Erfolge. HILDE zeichnet die
        Stationen ihrer Flucht aus Nazi-Deutschland nach.
        <br /> <br />
        <b className="text-2xl">Stimmen</b>
        <br />
        <i>
          Das Stück ist großartig gemacht, ist spannend, interessant,
          melancholisch, aber auch traurig.
        </i>{" "}
        Petra Röder, Insider-Magazin <br />
        <br />
        <b className="text-2xl">Textauszug</b>
        <br />
        London, eine Fußklinik (1934)
        <br />
        <br />
        ARTHUR: Du wirst mir fehlen.
        <br />
        HILDE: Wie?
        <br />
        ARTHUR: Wenn ich gestorben bin. Wenn ich einmal tot sein werde.
        <br />
        HILDE: Was redest du denn da? Und vor den Kundinnen.
        <br />
        ARTHUR: Die verstehn ja keine Silbe.
        <br />
        HILDE: Woher willst du das wissen.
        <br />
        ARTHUR: Es sollte sich ein Engländer die Mühe machen,
        <br />
        Deutsch zu lernen?
        <br />
        Kennst du auch nur einen guten Grund dafür.
        <br />
        HILDE: Ich weiß nicht.
        <br />
        Goethe vielleicht.
        <br />
        Oder Schiller.
        <br />
        Hölderlin?
        <br />
        ARTHUR: Goethe. Wie originell!
        <br />
        Die Deutschen heften sich die Namen ihrer Dichter ans Revers wie einen
        frisch polierten Orden, den sie auf ihrem Dachboden gefunden haben.{" "}
        <br />
        Schmücken sich am Tag mit dem erhabnen Klang der großen Namen, <br />
        und abends liegen sie in ihrem Bett, <br />
        mit einer Cowboygeschichte oder auch mit einem Kriminalroman.
        <br />
        HILDE: Goethe hin oder her.
        <br />
        Du bist gerade einmal 34.
        <br />
        Am Samstag woll'n wir heiraten.
        <br />
        Und da redest du von deinem Tod.
        <br />
        ARTHUR: Dass wir uns vor den Nazis retten konnten,
        <br />
        macht uns nicht unsterblich.
        <br />
        <br />
        (…)
        <br />
        <br />
        CHOR DER FLIEHENDEN:
        <br />
        wer sagt dir denn
        <br />
        fliehende
        <br />
        und schwört
        <br />
        dass eine heimat sei
        <br />
        unentbehrlich
        <br />
        der ort
        <br />
        und beschwerlicher nichts
        <br />
        als ein leben
        <br />
        im behelfszustand
        <br />
        die existenz <br />
        auf abruf <br />
        immerfort <br />
        herrsche ein heulen
        <br />
        und zähneklappern
        <br />
        wo eine hinter sich wirft
        <br />
        die markierungslinie
        <br />
        der uralten stadt
        <br />
        zittert die mauer
        <br />
        vom sprung
      </p>
    </>
  );
}
