import { Carousel } from "@mantine/carousel";
import { Spoiler } from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";
import Autoplay from "embla-carousel-autoplay";
import { nanoid } from "nanoid";
import { useRef } from "react";

export function RowOrCol({ firstCol, bilder, fotograph }) {
  const autoplay = useRef(Autoplay({ delay: 5000 }));

  const matches = useMediaQuery("(min-width: 768px)");

  return (
    <Spoiler
      classNames={{
        control: "text-black underline",
        content: "mt-5",
      }}
      maxHeight={500}
      showLabel="→ weiterlesen"
      hideLabel="→ weniger"
      transitionDuration={0}
    >
      <div className="flex flex-col-reverse md:flex-row">
        <div className="md:w-2/3">{firstCol}</div>
        <div className="md:w-1/3">
          <>
            {matches ? (
              bilder.map((bild) => (
                <img
                  key={"bild" + nanoid()}
                  className="w-full m-5 grayscale hover:grayscale-0"
                  alt={"bild"}
                  src={bild}
                />
              ))
            ) : (
              <Carousel
                classNames={{
                  root: " w-full ",
                  control: "hidden",
                }}
                draggable={false}
                plugins={[autoplay.current]}
              >
                {bilder.map((bild, i) => (
                  <Carousel.Slide key={"bild" + nanoid()}>
                    <img
                      className="md:m-5 object-cover object-center w-full max-h-[200px] grayscale hover:grayscale-0"
                      alt={"bild" + i}
                      src={bild}
                    />
                  </Carousel.Slide>
                ))}
              </Carousel>
            )}
          </>
          {fotograph && (
            <p className="mb-8 text-right md:mb-0">{`Fotos: ${fotograph}`}</p>
          )}
        </div>
      </div>
    </Spoiler>
  );
}
