import { Heading } from "../Heading";

export function EineJacke() {
  return (
    <>
      <Heading>Eine Jacke, die sich nach dem Winter sehnt</Heading>

      <p className="mb-8 md:mb-0 md:mr-8">
        <br /> Mit einer Übersetzung ins Ukrainische von Chrystyna Nazarkewytsch
        und Illustrationen von Merle Tebbe
        <br />
        <br /> Annotschka lebt mit ihrer Familie in der Fremde – ohne Pako,
        ihren Hund, und ohne Papa. Der hatte beim Abschied nur einen Wunsch: Sie
        sollen fröhlich sein. Ab und an gelingt es. Dann zieht Annotschka einen
        Strich auf der Mama-ist-fröhlich-Seite ihrer geheimen Liste. Doch{" "}
        <i>Mama ist traurig</i> liegt vorn – bis der erste Schnee fällt.
        <br />
        <br /> ISBN: 978-3-9825100-0-2
        <br /> Für 12,90 Euro auf{" "}
        <a
          target="_blank"
          href="https://www.einejacke.de"
          rel="noreferrer"
          className="underline"
        >
          www.einejacke.de
        </a>
      </p>
      <br />
      <p className="">
        <b className="text-2xl">Stimmen</b>
        <br />
        Almut Baumgarten erzählt empfindsam und mit poetischer Sprache von
        Annotschkas einsamen Tagen: Von der sprachlichen Isolation und dem
        plötzlichen Ende der Kindheit und von Geburtstagen, an denen ein Teil
        der Familie in Deutschland und der andere in der Ukraine ist.
        <br /> Lina Brünig, WDR 5 „Bücher“
        <br /> <br />
        Das Buch begeistert nicht nur kleine Herzchen, sondern auch die großen.
        Die Übersetzung ins Ukrainische transportiert die Emotionen der
        Protagonisten sehr präzise. Auch die Illustrationen sind besonders und
        sehr modern.
        <br /> Nathalia Banakh, Buchhandlung Lutz Heimhalt, Hamburg
      </p>
      <br />
      <p className="">
        <b className="text-2xl">Textauszug</b>
        <br /> Es ist Nacht über der Stadt. Tiefe, schwarze Nacht. Alle Lichter
        sind gelöscht. Keine Laterne erhellt die Straßen. Keine Lampe brennt
        hinter den Fenstern. Als hätte einer diese große Stadt mit einem Mal
        ausgeknipst. Und still ist es.
        <br />
        <br /> Nur in dem kleinen Haus am Rand der Stadt bellt ein Hund. Leise.
        Es ist mehr ein Wuff als ein Wau. Der Hund bellt im Schlaf. In seinem
        Hundetraum jagt er nach einem gelben Ball. Der Ball saust durch die
        blaue Luft – der Hund ihm hinterher –, saust irgendwo ins hohe Gras. Er
        riecht ihn ja. Den Duft nach Stoff und Wiesenerde, nach Mensch und
        Hundespucke, er kommt von dort, weht von der Waldseite in seine Nase.
        Ihm nach! Da! Schon schnappt er seinen Ball und galoppiert zurück zu
        Annotschka, als ihn ein Lärm aus seinem bunten Traum heraus und in das
        dunkle Zimmer reißt. Ein großes, grässliches Jaulen.
        <br />
        <br /> Annotschka hörte das Heulen nicht. Sie lag in einem fremden Land,
        in einer fremden Stadt, in einem fremden Haus, in einem fremden Bett.
        Allein der Mond vor dem Fenster war ihr vertraut. Er sah genauso aus wie
        zuhaus. Nur trug er einen fremden Namen. Wie alles um sie her. Und wie
        sie selbst. Die Menschen hier sagten Anna zu ihr. Zuhause hieß sie
        Annotschka. Anna sagte Papa nur, wenn es ernst wurde. Wenn sie ihrem
        kleinen Bruder auf die Finger schlug, weil er an ihren Zöpfen zog, zum
        Beispiel.
        <br />
        <br /> Auf dem Bahnsteig hatte er Anna gesagt. Mama und Sascha lagen in
        Babuschkas Arm. Babuschka küsste die beiden, immer wieder, immer im
        Wechsel und immer schneller. Ein Kuss für Mama, ein Kuss für Sascha.
        Einer für Mama, einer für Sascha. Für Mama, für Sascha. Mama, Sascha,
        Mama, Sascha. Tante Milena saß auf ihrem Koffer und machte Bilder mit
        dem Telefon. Und Papa hob Annotschka hoch auf seinen Arm. Obwohl sie
        doch dafür schon fast zu groß war.
        <br /> Nicht traurig sein, Anna, sagte er zu ihr. Mama ist bei dir. Mama
        passt auf euch auf. Auf dich und auf Sascha. Nicht traurig sein, Anna.
        Hast du gehört? Nur das ist es, was ich mir von euch wünsche, dass ihr
        fröhlich seid, Mama, Sascha und du. Mehr nicht.
        <br /> Dann sind sie eingestiegen. Tante Milena mit dem großen Koffer,
        Mama mit Sascha – und Annotschka mit ihrem Rucksack für Karten und
        Bücher, Stifte und Papier.
      </p>
    </>
  );
}
