import { createBrowserRouter, RouterProvider } from "react-router-dom";
import img2_1v2 from "./assets/memory/Memorie_korr23.0318.jpg";
import img2_2v2 from "./assets/memory/Memorie_korr23.0319.jpg";
import img1_1v2 from "./assets/memory/Memorie_korr23.032.jpg";
import img3_2v1 from "./assets/memory/Memorie_korr23.0324.jpg";
import img3_1v1 from "./assets/memory/Memorie_korr23.0325.jpg";
import img3_2v2 from "./assets/memory/Memorie_korr23.0326.jpg";
import img3_1v2 from "./assets/memory/Memorie_korr23.0327.jpg";
import img1_2v2 from "./assets/memory/Memorie_korr23.033.jpg";
import img2_1v1 from "./assets/memory/Memorie_korr23.0334.jpg";
import img2_2v1 from "./assets/memory/Memorie_korr23.0335.jpg";
import img1_1v1 from "./assets/memory/Memorie_korr23.034.jpg";
import img1_2v1 from "./assets/memory/Memorie_korr23.035.jpg";
import { Buecher } from "./pages/Buecher";
import { Buehne } from "./pages/Buehne";
import { Home } from "./pages/Home";
import { Kontakt } from "./pages/Kontakt";
import { Memory } from "./pages/Memory";
import { NotFound } from "./pages/NotFound";
import { Vita } from "./pages/Vita";

const imagesv2 = [img1_1v1, img1_2v1, img2_1v1, img2_2v1, img3_1v1, img3_2v1];
const imagesv1 = [img1_1v2, img1_2v2, img2_1v2, img2_2v2, img3_1v2, img3_2v2];

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/buecher",
    element: <Buecher />,
  },
  {
    path: "/memoryV1",
    element: <Memory images={imagesv1} />,
  },
  {
    path: "/memoryV2",
    element: <Memory images={imagesv2} />,
  },
  {
    path: "/buehne",
    element: <Buehne />,
  },
  {
    path: "/vita",
    element: <Vita />,
  },
  {
    path: "/kontakt",
    element: <Kontakt />,
  },
  {
    path: "*",
    element: <NotFound />,
  },
]);

function App() {
  return (
    <>
      <RouterProvider router={router} />
    </>
  );
}

export default App;
