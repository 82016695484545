import { Footer } from "../components/Footer";
import { HeaderMenu } from "../components/Navbar";

export function Page({ children }) {
  return (
    <div className="w-screen h-screen overflow-y-auto font-mono bg-gray-300">
      <HeaderMenu />

      {children}
      <Footer />
    </div>
  );
}
