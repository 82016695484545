import { Burger, Drawer, Group, Header, Stack } from "@mantine/core";
import { useDisclosure } from "@mantine/hooks";
import { useNavigate } from "react-router-dom";

const links = [
  { link: "/", label: "News" },
  { link: "/buecher", label: "Bücher" },
  { link: "/buehne", label: "Bühne" },
  { link: "/vita", label: "Vita" },
  { link: "/kontakt", label: "Impressum/Kontakt" },
];

export function HeaderMenu() {
  const [opened, { toggle }] = useDisclosure(false);
  const navigate = useNavigate();

  const items = links.map((link) => {
    return (
      <p
        key={link.label}
        className="relative transition duration-500 transform cursor-pointer hover:scale-125 group"
        onClick={() => navigate(link.link)}
      >
        {link.label}
        <span className="absolute left-0 w-0 h-0.5 transition-all bg-black -bottom-1 group-hover:w-full"></span>
      </p>
    );
  });

  return (
    <Header
      className="sticky font-mono text-black bg-gray-300 border-gray-600 "
      height={80}
    >
      <Drawer
        opened={opened}
        classNames={{
          drawer: "bg-gray-300",
          closeButton: "hover:bg-transparent text-black",
        }}
        onClose={() => toggle()}
        padding="xl"
        color="black"
        size="md"
      >
        <Stack spacing={5} className="font-mono text-black">
          {links.map((link) => {
            return (
              <p
                key={link.label}
                className="relative my-2 ml-3 transition duration-500 transform cursor-pointer hover:scale-110 group"
                onClick={() => {
                  navigate(link.link);
                  toggle();
                }}
              >
                {link.label}
                <span className="absolute left-0 w-0 h-0.5 transition-all bg-black -bottom-1 group-hover:w-full"></span>
              </p>
            );
          })}
        </Stack>
      </Drawer>
      <div className="flex items-center justify-between w-full h-full px-8">
        <p
          onClick={() => navigate("/")}
          className="text-2xl font-bold transition duration-500 cursor-pointer group md:text-4xl md:w-1/2 "
        >
          Almut Baumgarten
        </p>
        <Group
          spacing={5}
          className="justify-between hidden max-w-xl md:flex grow"
        >
          {items}
        </Group>
        <Burger
          color="black"
          className="block md:hidden"
          opened={opened}
          onClick={toggle}
          size="sm"
        />
      </div>
    </Header>
  );
}
