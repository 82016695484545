import { Divider, Stack } from "@mantine/core";
import fliegen2 from "../assets/fliegen/Fliegen1_Foto Miroslaw Nowotny.jpg";
import fliegen3 from "../assets/fliegen/Fliegen2_Foto Miroslaw Nowotny.jpg";
import fliegen1 from "../assets/fliegen/Fliegen_Foto Miroslaw Nowotny.jpg";
import hilde1 from "../assets/hilde/Hilde 2.jpg";
import hilde2 from "../assets/hilde/Hilde 3.jpg";
import hilde3 from "../assets/hilde/Hilde 9.jpg";
import silverday1 from "../assets/silverday/silverday1.jpg";
import silverday2 from "../assets/silverday/silverday2.jpg";
import silverday3 from "../assets/silverday/silverday3.jpg";
import tank1 from "../assets/tank/Tank06.jpg";
import tank2 from "../assets/tank/Tank10.jpg";
import tank3 from "../assets/tank/Tank13.jpg";
import tank4 from "../assets/tank/Tank14.jpg";
import { Fliegen } from "../components/buehne/Fliegen";
import { Hilde } from "../components/buehne/Hilde";
import { Silverday } from "../components/buehne/Silverday";
import { Tank } from "../components/buehne/Tank";
import { RowOrCol } from "../components/RowOrCol";
import { Page } from "./Page";

export function Buehne() {
  return (
    <Page>
      <Stack className="px-10 py-8">
        <RowOrCol
          firstCol={<Tank />}
          fotograph={"Isabelle Girard de Soucanton"}
          bilder={[tank3, tank2, tank1, tank4]}
        />

        <Divider color="black" my="sm" />

        <RowOrCol
          firstCol={<Hilde />}
          fotograph={"Hans-Jürgen Brehm-Seufert"}
          bilder={[hilde1, hilde2, hilde3]}
        />

        <Divider color="black" my="sm" />
        <RowOrCol
          firstCol={<Fliegen />}
          fotograph={"Miroslaw Nowotny"}
          bilder={[fliegen1, fliegen2, fliegen3]}
        />

        <Divider color="black" my="sm" />
        <RowOrCol
          firstCol={<Silverday />}
          fotograph={"Bozica Babic"}
          bilder={[silverday1, silverday2, silverday3]}
        />
      </Stack>
    </Page>
  );
}
