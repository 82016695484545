import { Heading } from "../Heading";

export function Tank() {
  return (
    <>
      <Heading>TANK | UA 2008 am Pfalztheater Kaiserslautern</Heading>
      <p className="mb-8 md:mb-0 md:mr-8">
        © Drei Masken Verlag
        <br /> <br />
        Ein Stück über Spielarten von Gewalt und über die Gewalt als Spiel:
        Minni und Lizzi sehen wie zwei Engel aus. Sie tragen stets ein Messer
        unterm Rock. Andere zu verletzen, ist für sie “wie Comedy gucken“. Auch
        Tank hat seinen Spaß daran. Nur dass er die Gewalt nicht eigenhändig
        ausüben mag. Tank zieht es vor, die Bilder zu betrachten, die er oder
        die Mädchen von den Angriffen aufzeichnen. Er bewegt sich nicht gern.
        Seine Mutter hält wie er lieber still. Nur dann gibt sie ein gutes Bild
        ab. Tanks Vater hingegen schwört auf die Bewegung. „Der Mensch“, sagt
        er, „muss einmal täglich in Bewegung und außer Atem kommen, damit die
        frische Luft in seinem Körper zirkuliert. Durchgelüftet werden muss der
        Menschenkörper einmal am Tag wie ein Haus.“ Immer fraglicher wird, was
        Wirklichkeit, was Abbild und was Fake ist. Mit einer Ausnahme: „Der
        Schmerz ist ja das einzige, dessen wir sicher sind.“
      </p>
      <p>
        <br /> <b className="text-2xl">Stimmen</b> <br />
        <i>
          Ähnlich wie in den Stücken einer Elfriede Jelinek sind die Figuren
          Textträger, die existieren, solange eine akribisch konstruierte
          Sprache durch sie hindurchfließt. Mit dem Unterschied, dass Baumgarten
          nicht wie die österreichische Nobelpreisträgerin überwiegend
          sprachliche Konventionen aus Alltag und TV auseinanderpflückt und neu
          collagiert, sondern ganz eigene Sprachbilder von mitunter befremdlich
          schöner Poesie generiert.{" "}
        </i>
        Anna Hahn, Nachtkritik
        <br /> <br />
        <i>
          Es ist ein Stück, das nachdenklich macht und bei aller Düsterkeit doch
          auch einen befreienden Witz an den Tag legt.{" "}
        </i>
        dpa, 13.12.2008
        <br /> <br />
        <i>
          Almut Baumgarten hat sich dem Thema Gewalt auf eine sehr mutige Weise
          genähert. Sie beschreibt eine Ästhetik der Grausamkeit. Und kleidet
          die Brutalität in das Unglaubliche: in die Schönheit.
        </i>{" "}
        Aus der Laudatio zum Jugendtheaterpreises Baden-Württemberg
      </p>{" "}
      <br />
      <p className="">
        <b className="text-2xl">Textauszug</b>
        <br />
        MINNI Es machen alle Platz.
        <br />
        LIZZI Wenn wir rennen.
        <br />
        MINNI Treten sie nach rechts.
        <br />
        LIZZI In dunkle Hauseingänge.
        <br />
        MINNI Oder nach links hin.
        <br />
        LIZZI Auf die Straße.
        <br />
        MINNI Bilden eine Gasse für unsere Flucht.
        <br />
        LIZZI Es kommt vor, dass wir zu laufen beginnen, dass wir rennen wie
        verrückt, obwohl.
        <br />
        MINNI Wir gar nichts ausgefressen haben.
        <br />
        LIZZI Nur einfach so.
        <br />
        MINNI Um die Leute vom Gehweg zu fegen.
        <br />
        LIZZI Und zu unserm Vergnügen.
        <br />
        MINNI Wie eben.
        <br />
        LIZZI Als wir die Straße lang rannten.
        <br />
        MINNI Ohne dass es nötig war.
        <br />
        LIZZI Nur so.
        <br />
        MINNI Bis wir hinter Atem kamen.
        <br />
        LIZZI Und unser Herz wie rasend schlug.
        <br />
        MINNI Da stürzten wir hinab.
        <br />
        LIZZI Stürzten die Stufen hinab in den Untergrund.
        <br />
        MINNI Die U-Bahn stand da wie für uns bereit.
        <br />
        LIZZI Irgendwann ging unser Atem wieder ruhig.
        <br />
        MINNI Das Herz schlug im gewohnten Tempo.
        <br />
        LIZZI Wir stiegen aus und lehnten an der Wand mit hochgezognen Knien.
        <br />
        MINNI Die kalten Kacheln an den Schultern fühlten sich gut an.
        <br />
        LIZZI Jedoch kroch ein Gefidel von irgendwo durch den Gang.
        <br />
        MINNI Und ein Penner schlich an unserer Wand entlang.
        <br />
        LIZZI Ich erkenne sie am Gang, die Penner, nicht an ihren Plastiktüten,
        dem <br />
        langen Bart und am Gestank, den sie verbreiten.
        <br />
        MINNI Sein Gang macht einen Penner aus.
        <br />
        LIZZI Ihr Kopf hängt vornüber und sie heben ihre Füße nicht.
        <br />
        MINNI Als würde eines Tages ihr Glück auf der Straße liegen.
        <br />
        LIZZI Schieben sie langsam Fuß vor Fuß und klebt ihr Blick auf der Erde.
        <br />
        MINNI Er kam genau auf uns zu.
        <br />
        LIZZI Und zog dann einen Bogen um uns rum.
        <br />
        MINNI Doch der Bogen, den er zog, war zu klein.
        <br />
        LIZZI Minni streckte nur ihr Bein.
      </p>
    </>
  );
}
