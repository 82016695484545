import { Carousel } from "@mantine/carousel";
import { Stack } from "@mantine/core";
import Autoplay from "embla-carousel-autoplay";
import { useRef } from "react";
import { Heading } from "../components/Heading";
import { Slides } from "../components/Slides";
import { Page } from "./Page";

export function Home() {
  const autoplay = useRef(Autoplay({ delay: 7000 }));

  return (
    <Page>
      <Carousel
        classNames={{
          root: " w-full text-3xl sm:text-5xl",
          control: "hidden",
        }}
        plugins={[autoplay.current]}
        draggable={false}
      >
        <Slides />
      </Carousel>
      <Stack className="px-10 py-14">
        <Heading>News</Heading>
        <div>
          <p>
            <b>Im Studio</b> <br /> Für die Sendung „Bücher“ auf WDR 5 durften
            wir – die Illustratorin Merle Tebbe und ich – im WDR-Studio in Köln
            mit Rebecca Link über{" "}
            <i>Eine Jacke, die sich nach dem Winter sehnt</i> sprechen. Das
            Interview zum Nachhören und die Rezension von Lina Brünig gibt es{" "}
            <a
              target="_blank"
              href="https://www1.wdr.de/radio/wdr5/sendungen/buecher/autor-im-gespraech/almut-baumgarten-100.html"
              rel="noreferrer"
              className="underline"
            >
              hier
            </a>
            .
          </p>
        </div>
        <div>
          <p>
            <b>Im Blütenstaub</b> <br /> Der Ort: Ein Haus. Innen. Die Zeit:
            Jetzt. Vergangen. Vorvergangen. Auf der Bühne: Acht Lebende und drei
            Tote. Der neue Theatertext <i>Im Blütenstaub</i> wurde am 18. März
            2023 an der Musik- und Kunstschule der Stadt Bielefeld uraufgeführt.
            Regie: Annelena Balke.
          </p>
        </div>
        <div>
          <p>
            <b>Druckfrisch: zweisprachige Erzählung</b> <br />{" "}
            <i>Eine Jacke, die sich nach dem Winter sehnt</i> erzählt von
            Annotschkas Erinnerungen und Erlebnissen, von wirklichen Gefühlen
            und unwirklichen Zimmern, von kleinen Geheimnissen und großen
            Überraschungen. In Worten und Bildern, die mal traurig, mal heiter,
            oft beides zugleich sind.
            <br /> Mit einer Übersetzung ins Ukrainische von Chrystyna
            Nazarkewytsch und Illustrationen von Merle Tebbe
            <br />{" "}
            <a
              target="_blank"
              href="https://www.einejacke.de"
              rel="noreferrer"
              className="underline"
            >
              Jetzt gemeinsam schauen und lesen.
            </a>
          </p>
        </div>
      </Stack>
    </Page>
  );
}
