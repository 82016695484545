import { Divider, Stack } from "@mantine/core";
import hund from "../assets/jacke/hund.jpg";
import zug from "../assets/jacke/zug.jpg";
import mucks2 from "../assets/mucks/K_Illu_1.jpg";
import mucks3 from "../assets/mucks/K_Illu_3.jpg";
import mucks from "../assets/mucks/mucksmenschenstill.jpg";

import { EineJacke } from "../components/buecher/EineJacke";
import { Mucksmenschen } from "../components/buecher/Mucksmenschen";
import { RowOrCol } from "../components/RowOrCol";
import { Page } from "./Page";

export function Buecher() {
  return (
    <>
      <Page>
        <Stack className="px-10 py-8">
          <RowOrCol firstCol={<EineJacke />} bilder={[hund, zug]} />

          <Divider color="black" my="sm" />

          <RowOrCol
            firstCol={<Mucksmenschen />}
            bilder={[mucks, mucks2, mucks3]}
          />
        </Stack>
      </Page>
    </>
  );
}
