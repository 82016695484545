import { Heading } from "../Heading";

export function Fliegen() {
  return (
    <>
      <Heading>FLIEGEN | UA 2010 am Theater Bautzen</Heading>

      <p className="mb-8 md:mb-0 md:mr-8">
        © Drei Masken Verlag
        <br />
        <br />
        Johannes und Lina leben gefährlich: Regelmäßig treffen sie sich an einem
        Bahnhof, um auf fahrende Züge zu springen. „dann rauschst du fort und
        schaust zurück, meinst fast, du könntest dich noch stehen sehn. und wie
        du immer kleiner wirst. in diesem krach. bist drauf und dran zu winken.
        wie zum abschied“ sagt Lina. Die beiden begegnen Philipp, einem
        Pflanzenkenner, der für gewöhnlich nicht über sich spricht. Lina und
        Johannes aber gelingt es, ihm nach und nach zu entlocken, warum er
        unterwegs ist ohne ein Ziel. In immer vertraulicheren Gesprächen,
        offenbaren die drei Jugendlichen einander, was sie sonst, durch einen
        Sprung auf den Zug, lieber hinter sich lassen.
      </p>
      <br />

      <p className="">
        <b className="text-2xl">Textauszug</b>
        <br />
        LINA nur für einen winzigen moment.
        <br />
        nur für die sekunde,
        <br />
        die du brauchst,
        <br />
        um aufzuspringen.
        <br />
        weil der zug,
        <br />
        auch wenn du anlauf nimmst,
        <br />
        ist er ja schneller als der mensch.
        <br />
        der augenblick,
        <br />
        wenn du dein eignes tempo überwindest,
        <br />
        wenn du dich quasi selber überholst,
        <br />
        der,
        <br />
        ja,
        <br />
        vielleicht ist der gefährlich.
        <br />
        -<br />
        aber dann,
        <br />
        dann rauschst du fort
        <br />
        und schaust zurück,
        <br />
        meinst fast, du könntest dich noch stehen sehn.
        <br />
        und wie du immer kleiner wirst
        <br />
        in diesem krach.
        <br />
        bist drauf und dran zu winken. <br />
        wie zum abschied.
        <br />
        (…)
        <br />
        PHILIPP es ist verboten.
        <br />
        PHILIPP entdeckt was in den gleisen, versucht es vom <br />
        bahnsteig aus zu erreichen, scheitert, späht nach links und <br />
        rechts, springt hinab in die gleise, pflückt ein <br />
        schmalblättriges greiskraut, und zieht sich wieder hinauf <br />
        auf den bahnsteig.
        <br />
        LINA das ist verboten.
        <br />
        warum machst du das?
        <br />
        PHILIPP deshalb
        <br />
        LINA was ist das?
        <br />
        PHILIPP senecio inaequidens. <br />
        LINA sorry,
        <br />
        kann kein spanisch.
        <br />
        wie wär's, du redest
        <br />
        ausnahmsweise so,
        <br />
        dass klar wird, was du meinst.
        <br />
        PHILIPP das war latein
        <br />
        nicht spanisch.
        <br />
        senecio inaequidens.
        <br />
        schmalblättriges greiskraut.
        <br />
        LINA aha.
        <br />
        PHILIPP nach der blüte
        <br />
        sehn sie aus wie greisenköpfe.
        <br />
        die früchte tragen kleine kronen
        <br />
        aus weichem zuckerweißem haar.
        <br />
        daher hat die gattung ihren namen.
        <br />
        greiskraut.
        <br />
        LINA greiskraut.
        <br />
        puh.
        <br />
        wenn ich 'ne blume wär,
        <br />
        dann wollt ich anders heißen.
        <br />
        greiskraut.
        <br />
        PHILIPP dafür gehört sie zu den asteroideae,
        <br />
        also zu den sternengleichen,
        <br />
        wenn man so will.
        <br />
        da.
      </p>
    </>
  );
}
