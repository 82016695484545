import { Stack } from "@mantine/core";
import { Page } from "./Page";

export function NotFound() {
  return (
    <Page>
      <Stack className="p-8">
        404 Page Not Found. <br />
        Hier ist nichts.
      </Stack>
    </Page>
  );
}
